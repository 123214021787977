//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$icons__font-path               : "../fonts/Blank-Theme-Icons/Blank-Theme-Icons" !default;

//  Names
$icons__font-name               : 'icons-blank-theme' !default; // ToDo UI                                         : we need to rename (it shouldn't use blank theme name) or move icon fonts to blank theme

//  Font families
$font-family__Taz                   : 'Taz';
$font-family__Taz-SemilightItalic   : 'Taz-SemilightItalic';
$font-family__Taz-Semilight         : 'Taz-Semilight';
$font-family__Taz-Regular           : 'Taz-Regular';
$font-family__Taz-Regular-Italic    : 'Taz-RegularItalic';
$font-family__Taz-Bold              : 'Taz-Bold';
$font-family__Taz-BoldItalic        : 'Taz-BoldItalic';

$font-family-name__base         : $font-family__Taz;
$font-family-name__open-sans    : 'Open Sans' !default;
$font-family__sans-serif        : 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace !default;

$font-family__base              : $font-family-name__base, $font-family-name__open-sans, $font-family__sans-serif !default;

//  Sizes
$root__font-size                : 62.5% !default; // Defines ratio between root font size and base font size, 1rem = 10px
$font-size-ratio__base          : 1.6 !default; // Defines ratio of the root font-size to the base font-size

$font-size-unit                 : rem !default; // The unit to which most typography values will be converted by default
$font-size-unit-ratio           : $root__font-size * 16 / 100 / 1% !default; // Ratio of the root font-size to the font-size unit
$font-size-unit-convert         : true !default; // Controls whether font-size values are converted to the specified font-size unit

$font-size__base                : $font-size-unit-ratio * $font-size-ratio__base * 1px !default; // 16px Base font size value in px
$font-size__xxl                 : ceil(3.125 * $font-size__base) !default; // 50px
$font-size__xl                  : ceil(2 * $font-size__base) !default; // 32px
$font-size__l                   : ceil(1.25 * $font-size__base) !default; // 20px
$font-size__s                   : ceil(0.875 * $font-size__base) !default; // 14px
$font-size__xs                  : floor(0.75 * $font-size__base) !default; // 12px

//  Weights
$font-weight__light             : 300 !default;
$font-weight__regular           : 400 !default;
$font-weight__heavier           : 500 !default;
$font-weight__semibold          : 600 !default;
$font-weight__bold              : 700 !default;

//  Styles
$font-style__base               : normal !default;
$font-style__emphasis           : italic !default;

//  Line heights
$line-height__base              : 1.1875 !default;
$line-height__computed          : floor($font-size__base * $line-height__base) !default;
$line-height__xl                : 1.7 !default;
$line-height__l                 : 1.5 !default;
$line-height__s                 : 1.33 !default;
$line-height__xs                : 1.2 !default;


//  Colors
$text__color                    : $primary__color !default;
$text__color__intense           : $primary__color__darker !default;
$text__color__muted             : $primary__color__lighter !default;

//
//  Indents
//  ---------------------------------------------

$indent__base                   : $line-height__computed !default; // 20px
$indent__xxxl                   : $indent__base * 3.25 !default; //65px
$indent__xxl                    : $indent__base * 2.5 !default; //50px
$indent__xll                    : $indent__base * 2.25 !default; //45px
$indent__xl                     : $indent__base * 2 !default; // 40px
$indent__xlm                    : $indent__base * 1.75 !default; // 35px
$indent__l                      : $indent__base * 1.5 !default; // 30px
$indent__m                      : $indent__base * 1.25 !default; // 25px
$indent__ml                     : $indent__base / 1.25 !default; //16px
$indent__s                      : $indent__base / 2 !default; // 10px
$indent__s2                     : $indent__base / 2.5 !default; // 8px
$indent__xs                     : $indent__base / 4 !default; // 5px
$indent__xss                    : $indent__base / 5 !default; // 4px

//
//  Borders
//  ---------------------------------------------

$border-color__base             : darken($page__background-color, 18%) !default;
$border-width__base             : 1px !default;

//
//  Links
//  ---------------------------------------------

$link__color                    : $c-text-black !default;
$link__text-decoration          : underline !default;

$link__visited__color           : $link__color !default;
$link__visited__text-decoration : none !default;

$link__hover__color             : $c-text-brown-grey !default;
$link__hover__text-decoration   : underline !default;

$link__active__color            : $c-text-brown-grey !default;
$link__active__text-decoration  : underline !default;

// Alternative Links

$link-alternative__color                    : $c-text-white !default;
$link-alternative__weight                   : $font-weight__regular !default;
$link-alternative__text-transform           : none;
$link-alternative__transition               : all 0.5s !default;
$link-alternative__font-style               : $font-style__base !default;
$link-alternative__text-decoration          : none !default;

$link-alternative__visited__color           : $link-alternative__color !default;
$link-alternative__visited__text-decoration : $link-alternative__text-decoration !default;

$link-alternative__hover__color             : $link-alternative__color !default;
$link-alternative__hover__text-decoration   : underline !default;

$link-alternative__active__color            : $link-alternative__color !default;
$link-alternative__active__text-decoration  : $link-alternative__text-decoration !default;

//
//  Focus
//  ---------------------------------------------

$focus__color                   : $c-background-black !default;
$focus__box-shadow              : 0 0 3px 1px $focus__color !default;
$focus__border                  : 1px solid $focus__color !default;

//
//  Lists
//  ---------------------------------------------

$list__color__base              : false !default;
$list__font-size__base          : false !default;
$list__margin-top               : 0 !default;
$list__margin-bottom            : $indent__m !default;

$list-item__margin-top          : 0 !default;
$list-item__margin-bottom       : $indent__s !default;

$dl__margin-top                 : 0 !default;
$dl__margin-bottom              : $indent__base !default;

$dt__margin-top                 : 0 !default;
$dt__margin-bottom              : $indent__xs !default;
$dt__font-weight                : $font-weight__bold !default;

$dd__margin-top                 : 0 !default;
$dd__margin-bottom              : $indent__s !default;

//
//  Paragraphs
//  ---------------------------------------------

$p__margin-top                  : 0 !default;
$p__margin-bottom               : $indent__s !default;

//
//  Headings
//  ---------------------------------------------

$heading__font-family__base     : false !default;
$heading__font-weight__base     : $font-weight__bold !default;
$heading__line-height__base     : 1.1 !default;
$heading__color__base           : $c-text-white !default;
$heading__font-style__base      : false !default;
$heading__margin-top__base      : $indent__base !default;
$heading__margin-bottom__base   : $indent__base !default;

$h1__font-size                  : $font-size__xxl !default; // 50px
$h1__font-color                 : $heading__color__base !default;
$h1__font-family                : $heading__font-family__base !default;
$h1__font-weight                : $heading__font-weight__base !default;
$h1__font-style                 : $heading__font-style__base !default;
$h1__line-height                : $heading__line-height__base !default;
$h1__margin-top                 : 0 !default;
$h1__margin-bottom              : $heading__margin-bottom__base !default;
$h1__font-size-desktop          : ceil(($font-size__base * 2.85)) !default; // 40px

$h2__font-size                  : ceil(($font-size__base * 2)) !default; // 32px
$h2__font-color                 : $heading__color__base !default;
$h2__font-family                : $heading__font-family__base !default;
$h2__font-weight                : $heading__font-weight__base !default;
$h2__font-style                 : $heading__font-style__base !default;
$h2__line-height                : $heading__line-height__base !default;
$h2__margin-top                 : $indent__m !default;
$h2__margin-bottom              : $heading__margin-bottom__base !default;

$h3__font-size                  : ceil(($font-size__base * 1.5)) !default; // 24px
$h3__font-color                 : $heading__color__base !default;
$h3__font-family                : $heading__font-family__base !default;
$h3__font-weight                : $heading__font-weight__base !default;
$h3__font-style                 : $heading__font-style__base !default;
$h3__line-height                : $heading__line-height__base !default;
$h3__margin-top                 : $indent__base * 0.75 !default;
$h3__margin-bottom              : $indent__s !default;

$h4__font-size                  : ceil(($font-size__base * 1.25)) !default;   //$font-size__base !default; // 16px
$h4__font-color                 : $heading__color__base !default;
$h4__font-family                : $heading__font-family__base !default;
$h4__font-weight                : $font-weight__bold !default;
$h4__font-style                 : $heading__font-style__base !default;
$h4__line-height                : $heading__line-height__base !default;
$h4__margin-top                 : $heading__margin-top__base !default;
$h4__margin-bottom              : $heading__margin-bottom__base !default;

$h5__font-size                  : ceil(($font-size__base * 0.875)) !default; // 14px
$h5__font-color                 : $heading__color__base !default;
$h5__font-family                : $heading__font-family__base !default;
$h5__font-weight                : $font-weight__bold !default;
$h5__font-style                 : $heading__font-style__base !default;
$h5__line-height                : $heading__line-height__base !default;
$h5__margin-top                 : $heading__margin-top__base !default;
$h5__margin-bottom              : $heading__margin-bottom__base !default;

$h6__font-size                  : ceil(($font-size__base * 0.75)) !default; // 12px
$h6__font-color                 : $heading__color__base !default;
$h6__font-family                : $heading__font-family__base !default;
$h6__font-weight                : $font-weight__bold !default;
$h6__font-style                 : $heading__font-style__base !default;
$h6__line-height                : $heading__line-height__base !default;
$h6__margin-top                 : $heading__margin-top__base !default;
$h6__margin-bottom              : $heading__margin-bottom__base !default;

$heading__small-color           : $primary__color !default;
$heading__small-line-height     : 1 !default;
$heading__small-size            : ($font-size__xs / $font-size__base) * 100% !default;

//  Code blocks
$code__background-color         : $panel__background-color !default;
$code__color                    : $primary__color__darker !default;
$code__font-size                : $font-size__s !default;
$code__padding                  : 2px 4px !default;

$pre__background-color          : $panel__background-color !default;
$pre__border-color              : $border-color__base !default;
$pre__border-width              : $border-width__base !default;
$pre__color                     : $primary__color__darker !default;

$kbd__background-color          : $panel__background-color !default;
$kbd__color                     : $primary__color__darker !default;

//  Blockquote
$blockquote__border-color       : $border-color__base !default;
$blockquote__border-width       : 0 !default;
$blockquote__content-before     : '\2014 \00A0' !default;
$blockquote__font-size          : $font-size__base !default;
$blockquote__font-style         : $font-style__emphasis !default;
$blockquote__margin             : 0 0 $indent__base $indent__xl !default;
$blockquote__padding            : 0 !default;

$blockquote-small__color        : $primary__color !default;
$blockquote-small__font-size    : $font-size__xs !default;

$cite__font-style               : $font-style__base !default;

//  Misc
$hr__border-color               : $border-color__base !default;
$hr__border-style               : solid !default;
$hr__border-width               : $border-width__base !default;

$mark__color                    : $primary__color__dark !default;
$mark__background-color         : $panel__background-color !default;

$abbr__border-color             : $border-color__base !default;

//  Disable filters output in css
$disable-filters                : false !default;
