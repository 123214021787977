html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

.page.messages .messages-custom-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem;
  background: white;
  border: 2px solid black;
  box-shadow: 6px 6px 15px 6px rgba(0, 0, 0, 0.3);
  z-index: 999;
  box-sizing: border-box;
  max-width: 650px; }

.page.messages .message {
  text-align: left;
  padding: 15.2px 9.5px; }
  .page.messages .message > div {
    position: relative;
    padding-left: 4.5rem;
    display: inline-block;
    font-size: 1.8rem;
    color: #000000; }
    .page.messages .message > div:before, .page.messages .message > div::before {
      top: 46%;
      transform: translateY(-50%);
      left: 0;
      margin: 0; }
  .page.messages .message.hidden {
    display: none !important; }
  .page.messages .message.error, .page.messages .message.info, .page.messages .message.success {
    font-size: 1.8rem;
    line-height: 1.8rem; }

.page.messages .close-all-messages {
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 17px;
  right: 8px;
  transform: translateY(-50%);
  background-image: url("../images/icon-close.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer; }
  .page.messages .close-all-messages:hover {
    opacity: 0.7; }

.page.messages .message-close {
  display: none;
  position: absolute;
  right: 38px;
  width: 14px;
  height: 14px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("../images/icon-close.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer; }
  .page.messages .message-close:hover {
    opacity: 0.7; }

@media (max-width: 768px) {
  .page.messages .messages-custom-container {
    padding: 4rem 2rem; }
  .page.messages .message {
    padding: 15.2px 0; }
    .page.messages .message > div {
      font-size: 1.6rem; }
    .page.messages .message.error, .page.messages .message.info, .page.messages .message.success {
      font-size: 1.6rem;
      line-height: 1.6rem; } }

@media (max-width: 640px) {
  .page.messages .messages-custom-container {
    width: 100%;
    max-width: 90%; } }

.page-print .logo {
  float: none;
  display: block;
  text-align: left; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed; }
    .table-wrapper table td {
      width: auto; }
    .table-wrapper table > tfoot > tr:first-child {
      border-top: 1px solid #999; }
  .box,
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 1cm; }
  .block-content,
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  .block-content {
    page-break-before: avoid; }
  .block-title,
  h2,
  h3 {
    page-break-after: avoid; }
  .nav-toggle {
    display: none !important; }
  .sidebar,
  .nav-sections,
  .header.content > *[class],
  .panel.wrapper > *[class],
  .footer.content > *[class] {
    display: none; }
  .logo,
  .footer .copyright {
    display: block !important;
    margin: 10px 0; }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto; }
  .column.main {
    width: 100% !important;
    float: none !important; }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right; }
  .footer.content {
    padding: 0; } }
